import React from 'react';

import Layout from 'components/Layout';
import Contact from 'components/Contact';

const title = 'Motoroma | Kontakt - jak do nas trafić - Witaszyczki k/Jarocina';
const description =
  'Serwis Motoroma - Witaszyczki 36 k/Jarocina, 63-230 Witaszyce';

const ContactPage = () => (
  <Layout
    smallHero
    title="Kontakt"
    slug="/contact"
    metaTitle={title}
    metaDescription={description}
  >
    <Contact withoutHeader stacked />
  </Layout>
);

export default ContactPage;
